import React from "react"

import BarStep from "./partials/bar-step"
import AddCar from "../car/add-car"
import Layout from "../../components/layout";

const AddCarPage = () => {

  return (
    <div>
      <Layout>
        <BarStep selected="step1" />
      <AddCar navigateTo='/profile/add-car-added' currentPage="profile"/>
      </Layout>
    </div>
  );
};

export default AddCarPage
